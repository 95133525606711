import { render, staticRenderFns } from "./TenantFilter.vue?vue&type=template&id=3b817701&scoped=true"
import script from "./TenantFilter.vue?vue&type=script&lang=ts"
export * from "./TenantFilter.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b817701",
  null
  
)

export default component.exports