import moment from 'moment';
import { rrulestr, Weekday } from 'rrule'


// Autocomplete search function used in the v-autocomplete components
export function autoComplete(item: any, queryText: string, itemText=""): boolean {
  if(!queryText) return true; // undefined case
  
  const searchText = queryText.toLowerCase();
  
  if(typeof item === 'string') {
    const prop = item.toLowerCase();
    return prop.indexOf(searchText) > -1 || prop === searchText
  }
  
  for(const key of Object.keys(item)) {
    if(key === 'createdAt' || key === 'updatedAt' || key === 'deletedAt') {
      continue;
    }
    if(item[key] && typeof item[key] === 'string') {
      const prop = item[key].toLowerCase();
      if(prop.indexOf(searchText) > -1 || prop === searchText) {
        return true;
      }
    }
  }
  return false;
}

export function filterItem(value: any, search: string | null, item: any): boolean {
  // return Object.values(item).some((v: any) => v && v.toString().toLowerCase().includes(search))
  return autoComplete(item, search || '', '');
}

export function extractErrorMessage(error: any): string {
  if(typeof error === 'string') {
    return error;
  } else if (error?.response && error?.response?.data && error?.response.data.type === 'errors') {
    let msg = error?.response.data.errors[0].title + ' (' + error?.response.data.errors[0].status+ ')';

    if(error?.response.data.errors[0]?.detail)
      msg = msg + ': ' + error?.response.data.errors[0]?.detail;
      
    // if(error?.response.data.errors[0]?.source)
    //   msg = msg + ' [' + error?.response.data.errors[0]?.source?.pointer + ']';

    return msg;
  } else {
    return error;
  }
}

export function parseJwt (token: string) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}

const deStrings = {
    every: 'jede(n)',
    until: 'bis',
    day: 'Tag',
    days: 'Tage',
    week: 'Woche',
    weeks: 'Wochen',
    on: 'ein',
    in: 'in',
    'on the': 'am',
    for: 'für',
    and: 'und',
    or: 'oder',
    the: "dem",
    at: 'bei',
    last: 'zuletzt',
    '(~ approximate)': '(~ approximativ)',
    st: '.',
    nd: '.',
    rd: '.',
    th: '.',
    times: 'Mal',
    time: 'Zeit',
    minute: "Minute",
    minutes: 'Minuten',
    hour: 'Stunde',
    hours: 'Stunden',
    weekdays: 'Wochentage',
    weekday: 'Wochentag',
    months: 'Monate',
    month: 'Monat',
    years: 'Jahre',
    year: 'Jahr'
} as any;

export const reportTypedColumns = (reportType: string) => {
  if(reportType === 'device-report') {
    
    return [{ header: "Property" },
      { text: "Property UUID", value: "device_property_uuid" },
      { text: "Property Name", value: "device_property_name" },
      { text: "Property AKS ID", value: "device_property_aksId" },
      { text: "Property Identifier", value: "device_property_identifier" },
      { text: "Property Street", value: "device_property_street" },
      { text: "Property Postal Code", value: "device_property_postalCode" },
      { text: "Property City", value: "device_property_city" },
      { text: "Property Country", value: "device_property_country" },
      { text: "Property Created At", value: "device_property_createdAt" },
      { text: "Property Updated At", value: "device_property_updatedAt" },

    { header: "Connector" },
      { text: "Connector UUID", value: "device_connector_uuid" },
      { text: "Connector Name", value: "device_connector_name" },
      { text: "Connector Created At", value: "device_connector_createdAt" },
      { text: "Connector Updated At", value: "device_connector_updatedAt" },

    { header: "Device" },
      { text: "Device UUID", value: "device_uuid" },
      { text: "Device Name", value: "device_name" },
      { text: "Device AKS ID", value: "device_aksId" },
      { text: "Device Local AKS ID", value: "device_localAksId" },
      { text: "Device Description", value: "device_description" },
      { text: "Device Unit", value: "device_unit" },
      { text: "Device Type", value: "device_type" },
      { text: "Device Frequency", value: "device_frequency" },
      { text: "Device Interval", value: "device_interval" },
      { text: "Device Aggregation Method", value: "device_aggregation" },
      { text: "Device Reading Count", value: "device_reading_count" },
      { text: "Device Created At", value: "device_createdAt" },
      { text: "Device Updated At", value: "device_updatedAt" },

    { header: "Reading" },    
      { text: "Reading UUID", value: "device_reading_uuid" },  
      { text: "Reading Value", value: "device_reading_value" },
      { text: "Reading Value Aggregate", value: "device_reading_aggregate" },
      { text: "Reading Timestamp", value: "device_reading_timestamp" }, 
      { text: "Reading Created At", value: "device_reading_createdAt" },
      { text: "Reading Updated At", value: "device_reading_updatedAt" },

    ];
    
  } else if(reportType === 'reading-report') {
    
    return [{ header: "Property" },
      { text: "Property UUID", value: "reading_device_property_uuid" },
      { text: "Property Name", value: "reading_device_property_name" },
      { text: "Property AKS ID", value: "reading_device_property_aksId" },
      { text: "Property Identifier", value: "reading_device_property_identifier" },
      { text: "Property Street", value: "reading_device_property_street" },
      { text: "Property Postal Code", value: "reading_device_property_postalCode" },
      { text: "Property City", value: "reading_device_property_city" },
      { text: "Property Country", value: "reading_device_property_country" },
      { text: "Property Created At", value: "reading_device_property_createdAt" },
      { text: "Property Updated At", value: "reading_device_property_updatedAt" },

    { header: "Connector" },
      { text: "Connector UUID", value: "reading_device_connector_uuid" },
      { text: "Connector Name", value: "reading_device_connector_name" },
      { text: "Connector Created At", value: "reading_device_connector_createdAt" },
      { text: "Connector Updated At", value: "reading_device_connector_updatedAt" },

    { header: "Device" },
      { text: "Device UUID", value: "reading_device_uuid" },
      { text: "Device Name", value: "reading_device_name" },
      { text: "Device AKS ID", value: "reading_device_aksId" },
      { text: "Device Local AKS ID", value: "reading_device_localAksId" },
      { text: "Device Description", value: "reading_device_description" },
      { text: "Device Unit", value: "reading_device_unit" },
      { text: "Device Type", value: "reading_device_type" },
      { text: "Device Frequency", value: "reading_device_frequency" },
      { text: "Device Interval", value: "reading_device_interval" },
      { text: "Device Aggregation Method", value: "reading_device_aggregation" },
      { text: "Device Reading Count", value: "reading_device_reading_count" },
      { text: "Device Created At", value: "reading_device_createdAt" },
      { text: "Device Updated At", value: "reading_device_updatedAt" },

    { header: "Reading" },    
      { text: "Reading UUID", value: "reading_uuid" },  
      { text: "Reading Value", value: "reading_value" },
      { text: "Reading Timestamp", value: "reading_timestamp" },
      { text: "Reading Created At", value: "reading_createdAt" },
      { text: "Reading Updated At", value: "reading_updatedAt" },
    ];
      
  } else if(reportType === 'metering-report') {
    
    return [{ header: "Property" },
      { text: "Property UUID", value: "property_uuid" },
      { text: "Property Name", value: "property_name" },
      { text: "Property AKS ID", value: "property_aksId" },
      { text: "Property Identifier", value: "property_identifier" },
      { text: "Property Street", value: "property_street" },
      { text: "Property Postal Code", value: "property_postalCode" },
      { text: "Property City", value: "property_city" },
      { text: "Property Country", value: "property_country" },
      { text: "Property Created At", value: "property_createdAt" },
      { text: "Property Updated At", value: "property_updatedAt" },
      { text: "Property Device Count", value: "property_deviceCount" },
      { text: "Property Empty Device Count", value: "property_emptyDeviceCount" },
      { text: "Property Overdue Device Count", value: "property_overdueDeviceCount" },

    { header: "Last Reading" },    
      { text: "Last Reading UUID", value: "property_lastReading_uuid" },  
      { text: "Last Reading Value", value: "property_lastReading_value" },
      { text: "Last Reading Timestamp", value: "property_lastReading_timestamp" }, 
      { text: "Last Reading Created At", value: "property_lastReading_createdAt" },
      { text: "Last Reading Updated At", value: "property_lastReading_updatedAt" },

    ];
    
  } else if(reportType === 'billing-report') {

    return [{ header: "Property" },
      { text: "Property UUID", value: "billing_device_property_uuid" },
      { text: "Property Name", value: "billing_device_property_name" },
      { text: "Property AKS ID", value: "billing_device_property_aksId" },
      { text: "Property Identifier", value: "billing_device_property_identifier" },
      { text: "Property Street", value: "billing_device_property_street" },
      { text: "Property Postal Code", value: "billing_device_property_postalCode" },
      { text: "Property City", value: "billing_device_property_city" },
      { text: "Property Country", value: "billing_device_property_country" },
      { text: "Property Created At", value: "billing_device_property_createdAt" },
      { text: "Property Updated At", value: "billing_device_property_updatedAt" },

    { header: "Connector" },
      { text: "Connector UUID", value: "billing_connector_uuid" },
      { text: "Connector Name", value: "billing_connector_name" },
      { text: "Connector Device Count", value: "billing_connector_device_count" },
      { text: "Connector Created At", value: "billing_connector_createdAt" },
      { text: "Connector Updated At", value: "billing_connector_updatedAt" },

    { header: "Device" },
      { text: "Device UUID", value: "billing_device_uuid" },
      { text: "Device Name", value: "billing_device_name" },
      { text: "Device AKS ID", value: "billing_device_aksId" },
      { text: "Device Local AKS ID", value: "billing_device_localAksId" },
      { text: "Device Description", value: "billing_device_description" },
      { text: "Device Unit", value: "billing_device_unit" },
      { text: "Device Type", value: "billing_device_type" },
      { text: "Device Frequency", value: "billing_device_frequency" },
      { text: "Device Interval", value: "billing_device_interval" },
      { text: "Device Aggregation Method", value: "billing_device_aggregation" },
      { text: "Device Reading Count", value: "billing_device_reading_count" },
      { text: "Device Created At", value: "billing_device_createdAt" },
      { text: "Device Updated At", value: "billing_device_updatedAt" },
      { text: "Device Connector UUID", value: "billing_device_connector_uuid" },
      { text: "Device Connector Name", value: "billing_device_connector_name" },

    { header: "Reading" },    
      { text: "Reading UUID", value: "billing_device_reading_uuid" },  
      { text: "Reading Value", value: "billing_device_reading_value" },
      { text: "Reading Timestamp", value: "billing_device_reading_timestamp" },
      { text: "Device Created At", value: "billing_device_reading_createdAt" },
      { text: "Device Updated At", value: "billing_device_reading_updatedAt" },

    { header: "Billing" },    
      { text: "Billing Type", value: "billing_type" },  
      { text: "Billing Subtype", value: "billing_subtype" },  
      { text: "Billing Price", value: "billing_price" },  
      { text: "Billing PerUsePrice", value: "billing_perUsePrice" }, 
      { text: "Billing Start Date", value: "billing_start" },  
      { text: "Billing End Date", value: "billing_end" },  
      { text: "Billing Number of Days", value: "billing_days" },  
    ];

  } else if(reportType === 'iotdevice-report') {
    
    return [
      
    { header: "IoT Device" },
      { text: "IoT Device UUID", value: "iotdevice_uuid" },
      { text: "IoT Device Serialnumber", value: "iotdevice_serialnumber" },
      { text: "IoT Device Description", value: "iotdevice_description" },
      { text: "IoT Device Type", value: "iotdevice_type" },
      { text: "IoT Device Hardware Version", value: "iotdevice_hardware_version" },
      { text: "IoT Device Connectivity", value: "iotdevice_connectivity" },
      { text: "IoT Device Created At", value: "device_createdAt" },
      { text: "IoT Device Updated At", value: "device_updatedAt" },

    { header: "IoT Device Port" },
      { text: "IoT Device Port UUID", value: "iotdevice_uuid" },
      { text: "IoT Device Port Number", value: "iotdevice_port_portnumber" },
      { text: "IoT Device Port Type", value: "iotdevice_port_type" },
      { text: "IoT Device Created At", value: "device_createdAt" },
      { text: "IoT Device Updated At", value: "device_updatedAt" },
      
    { header: "Tenant" },
      { text: "Tenant UUID", value: "iotdevice_tenant_uuid" },
      { text: "Tenant Name", value: "iotdevice_tenant_name" },
      { text: "Tenant Created At", value: "iotdevice_tenant_createdAt" },
      { text: "Tenant Updated At", value: "iotdevice_tenant_updatedAt" },
        
      { header: "Property" },
      { text: "Property UUID", value: "iotdevice_property_uuid" },
      { text: "Property Name", value: "iotdevice_property_name" },
      { text: "Property AKS ID", value: "iotdevice_property_aksId" },
      { text: "Property Identifier", value: "iotdevice_property_identifier" },
      { text: "Property Street", value: "iotdevice_property_street" },
      { text: "Property Postal Code", value: "iotdevice_property_postalCode" },
      { text: "Property City", value: "iotdevice_property_city" },
      { text: "Property Country", value: "iotdevice_property_country" },
      { text: "Property Created At", value: "iotdevice_property_createdAt" },
      { text: "Property Updated At", value: "iotdevice_property_updatedAt" },

    { header: "Connector" },
      { text: "Connector UUID", value: "iotdevice_connector_uuid" },
      { text: "Connector Name", value: "iotdevice_connector_name" },
      { text: "Connector Created At", value: "iotdevice_connector_createdAt" },
      { text: "Connector Updated At", value: "iotdevice_connector_updatedAt" },

    { header: "Device (Mapping)" },
      { text: "Device UUID", value: "iotdevice_mapping_device_uuid" },
      { text: "Device Name", value: "iotdevice_mapping_device_name" },
      { text: "Device AKS ID", value: "iotdevice_mapping_device_aksId" },
      { text: "Device Local AKS ID", value: "iotdevice_mapping_device_localAksId" },
      { text: "Device Description", value: "iotdevice_mapping_device_description" },
      { text: "Device Unit", value: "iotdevice_mapping_device_unit" },
      { text: "Device Type", value: "iotdevice_mapping_device_type" },
      { text: "Device Frequency", value: "iotdevice_mapping_device_frequency" },
      { text: "Device Interval", value: "iotdevice_mapping_device_interval" },
      { text: "Device Aggregation Method", value: "iotdevice_mapping_device_aggregation" },
      { text: "Device Reading Count", value: "iotdevice_mapping_device_reading_count" },
      { text: "Device Created At", value: "iotdevice_mapping_device_createdAt" },
      { text: "Device Updated At", value: "iotdevice_mapping_device_updatedAt" },

    { header: "Reading (Mapping)" },    
      { text: "Reading UUID", value: "iotdevice_mapping_reading_uuid" },  
      { text: "Reading Value", value: "iotdevice_mapping_reading_value" },
      { text: "Reading Timestamp", value: "iotdevice_mapping_reading_timestamp" }, 
      { text: "Reading Created At", value: "iotdevice_mapping_reading_createdAt" },
      { text: "Reading Updated At", value: "iotdevice_mapping_reading_updatedAt" },

    ];
  } else {
    return [];
  }
}

export const RRuleToText = (strRRule: string, locale: string): string => {
  const language = {
    dayNames: moment.weekdays(),
    monthNames: moment.months()
  } as any;

  const getText = (id: string | number | Weekday): string => {
    return deStrings[String(id)];
  };

  const dateFormat = (year: number, month: string | number, day: number) =>
    moment()
      .date(day)
      .year(year)
      .month(month)
      .format("LL");

  if(locale === 'de') {
    return strRRule ? rrulestr(strRRule).toText(getText, language, dateFormat) : "";
  } else {
    return rrulestr(strRRule).toText();
  }
};

export const objectsToCSV = (objectList: any[]): string => {
  let csvContent = "";

  csvContent += [
        Object.keys(objectList[0]).join(";"),
        ...objectList.map((item: any) => Object.values(item).join(";").replace(/\r?\n|\r/g, ""))
      ].join("\n").replace(/(^\[)|(\]$)/gm, "");

  return csvContent;
};