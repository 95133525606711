
    import Vue from 'vue'

    import Chart from '@/components/visualization/Chart.vue';
    import DeviceInfoDialog from '@/components/entities/device/DeviceInfoDialog.vue';
    import ReadingService from '@/services/ReadingService';
    import { autoComplete } from '@/utils/Util';
    import TimeRangeFilter from '../filters/TimeRangeFilter.vue';
    import AggregationIntervalFilter from '../filters/AggregationIntervalFilter.vue';
    import SelectionFilter from '../filters/SelectionFilter.vue';
    import { ReadingAggregationFrequencies, ReadingAggregationFunctions } from '../entities/reading/ReadingCollections';
    import ExtendedMultiXChart from '@/components/visualization/ExtendedMultiXChart.vue';

    export default Vue.extend({
        name: 'CompareChartCard',

        components: {
            Chart,
            DeviceInfoDialog,
            TimeRangeFilter,
            AggregationIntervalFilter,
            SelectionFilter,
            ExtendedMultiXChart
        },
        props: ['item', 'index'],
        data: (vm: any): any => ({
            loadingDevices: false,
            requiredSelect: [
                (v: any) => (!!v && Object.keys(v).length > 0) || vm.requiredError
            ],
            loading: false,
            deviceSelection: vm.item.config.devices,
            selectionData: [] as any[],
            timeFunctions: ReadingAggregationFunctions,
            selectedChartTypes: vm.item.config.types ?? ['bar', 'bar'],
            selectedAggregations: vm.item.config.aggregations ?? [{ interval: "1", frequency: "daily", function: 'last' }, { interval: "1", frequency: "daily", function: 'last' }],
            timeFrequencies: ReadingAggregationFrequencies,
        }),
        async created() {
            if(this.deviceSelection.length===1) {
                this.deviceSelection.push(this.deviceSelection[0]);
            }

            if(this.index && parseInt(this.index))
                await this.delay(parseInt(this.index)*100);


            for(let i=0; i<this.selectedAggregations; i++) {
                if(!('from' in this.selectedAggregations[i]) && !('until' in this.selectedAggregations[i])) {
                    const until = new Date();
                    const from = new Date();
                    from.setUTCMonth(until.getUTCMonth()-6);
                    this.selectedAggregations[i].from = from.toISOString();
                    this.selectedAggregations[i].until = until.toISOString();
                }
            }

            this.getData();
        },
        watch: {
            selectedChartTypes(): any {
                this.onConfigChange();
            },
            selectedAggregations(): any {
                this.onConfigChange();
                this.getData();
            },
        },
        methods: {
            delay(ms: number) {
                return new Promise(res => setTimeout(res, ms));
            },
            onChartDownload() {
                // s
            },
            onDelete() {
                this.$emit('delete', this.item);
            },
            onRefresh() {
                this.getData();
            },
            onShowReadings() {
                this.$router.push({ name: 'Readings', params: { device: this.item.device } });
            },    
            onConfigChange() {
                let citem = { ...this.item, config: { devices: this.item.config.devices, types: this.selectedChartTypes, aggregations: this.selectedAggregations } }
                this.$emit('update:item', citem);
            },
            autoComplete,
            async getData() {
                for (let i = 0; i < this.deviceSelection.length; i++) {
                    await this.getDeviceData(i);
                }
            },
            async getDeviceData(index: number) {
                this.loading = true;
                // console.log(this.selectedAggregations[index])
                let aggregationFrom = this.selectedAggregations[index].from;
                let aggregationUntil = this.selectedAggregations[index].until;

                if(!aggregationFrom || !aggregationUntil) {
                    this.loading = false;
                    return;
                }

                let aggregationInterval = this.selectedAggregations[index].interval;
                let aggregationFrequency = this.selectedAggregations[index].frequency;
                let aggregationFunction = this.selectedAggregations[index].function;
       
                try {
                    const data = await ReadingService.getAllAggregationPages(this.currentTenant, this.deviceSelection[index].uuid, aggregationInterval, aggregationFrequency, aggregationFunction, { from: aggregationFrom, until: aggregationUntil });
                    // console.log(data);
                    if(index < this.selectionData.length) {
                        this.selectionData.splice(index, 1, { device: this.deviceSelection[index], data: data });
                    } else {
                        this.selectionData.push({ device: this.deviceSelection[index], data: data });
                    }
                } catch(error) {
                    console.log(error);
                }
                this.loading = false;
            },
        },
        computed: {
            chartLabels(): any[] {
                return this.selectionData.map((value: any, index: number) => 'X-' + index + ': ' + (value.device.description.length > 70 ? value.device.description.substring(0, 70)+'...' : value.device.description) + ', ' + value.device.unit);
            },
            requiredError(): any {
                return this.$t('required');
            },
            currentTenant(): any {
                return this.$root.$store.state.session.selectedTenant.uuid;
            },
            chartData(): any[] {
                return this.selectionData.map((value: any) => value.data);
            },
            timeUnits(): any[] {
                return [
                {name: this.$t('timeUnit.minutes'), frequency: 'MINUTELY' },
                {name: this.$t('timeUnit.hours'), frequency: 'HOURLY'}, 
                {name: this.$t('timeUnit.days'), frequency: 'DAILY'},
                {name: this.$t('timeUnit.weeks'), frequency: 'WEEKLY'},
                {name: this.$t('timeUnit.months'), frequency: 'MONTHLY'},
                {name: this.$t('timeUnit.years'), frequency: 'YEARLY'}
            ]
            },
            timeRanges(): any[] {
                return [
                    { value: "1h", label: "1 " + this.$t('timeUnit.hours'), interval: "1", frequency: "MINUTELY" },
                    { value: "6h", label: "6 " + this.$t('timeUnit.hours'), interval: "15", frequency: "MINUTELY" },
                    { value: "12h", label: "12 " + this.$t('timeUnit.hours'), interval: "15", frequency: "MINUTELY" },
                    { value: "1d", label: "1 " + this.$t('timeUnit.day'), interval: "1", frequency: "HOURLY" },
                    { value: "1w", label: "1 " + this.$t('timeUnit.week'), interval: "1", frequency: "DAILY" },
                    { value: "1m", label: "1 " + this.$t('timeUnit.month'), interval: "1", frequency: "DAILY" },
                    { value: "6m", label: "6 " + this.$t('timeUnit.months'), interval: "1", frequency: "WEEKLY" },
                    { value: "1y", label: "1 " + this.$t('timeUnit.year'), interval: "1", frequency: "MONTHLY" },
                    { value: "3y", label: "3 " + this.$t('timeUnit.years'), interval: "1", frequency: "MONTHLY" },
                ];
            }
        },
    })
