import { render, staticRenderFns } from "./SimpleFiltersCard.vue?vue&type=template&id=fc370d28&scoped=true"
import script from "./SimpleFiltersCard.vue?vue&type=script&lang=ts"
export * from "./SimpleFiltersCard.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fc370d28",
  null
  
)

export default component.exports