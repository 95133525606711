
  import Vue from 'vue'
  import DevicesTable from '@/components/entities/device/DevicesTable.vue'
  import DeviceCreateDialog from '@/components/entities/device/DeviceCreateDialog.vue'
  import EntityFiltersCard from '@/components/filters/EntityFiltersCard.vue'

  import InfoCard from '@/components/misc/InfoCard.vue'

  import DeviceService from '@/services/DeviceService';
  import { extractErrorMessage } from '@/utils/Util'
import ConnectorService from '@/services/ConnectorService'
import PropertyService from '@/services/PropertyService'

  export default Vue.extend({
    name: 'DevicesView',

    components: {
      DevicesTable,
      InfoCard,
      DeviceCreateDialog,
      EntityFiltersCard,
    },
    data: () => ({
      devices: [] as any,
      dataLoaded: false,
      dataError: false,
      alertText: "",
      alertType: "error",
      filters: {} as any,
      filtersConfig: [
        { attribute: 'aksId', always: true },
        { attribute: 'localAksId', always: false },
        { attribute: 'unit', always: false },
        { attribute: 'property', always: true },
        { attribute: 'connector', always: true },
        { attribute: 'tags', always: true },
        { attribute: 'createdAt', always: false },
        { attribute: 'deleted', always: false },
        { attribute: 'isRelative', always: false },
        { attribute: 'alerting', always: false },
        { attribute: 'empty', always: false }
      ]
    }),
    async created() {
      
      if('aksId' in this.$route.query) {
        Vue.set(this.filters, 'aksId', this.$route.query.aksId);
      }
      if('connectorId' in this.$route.query) {
        try {
          const connector = await ConnectorService.getSingle(this.currentTenant, this.$route.query.connectorId as string);
          Vue.set(this.filters, 'connector', connector);
        } catch {}
      }
      if('propertyId' in this.$route.query) {
        try {
          const property = await PropertyService.getSingle(this.currentTenant, this.$route.query.propertyId as string);
          Vue.set(this.filters, 'property', property);
        } catch {}
      }
      
      if('connector' in this.$route.params) {
        // this.filters.connector = this.$route.params.connector;
        Vue.set(this.filters, 'connector', this.$route.params.connector);
      }
      if('property' in this.$route.params) {
        // this.filters.property = this.$route.params.property;
        Vue.set(this.filters, 'property', this.$route.params.property);
      }
      if('device' in this.$route.params) {
        // this.filters.property = this.$route.params.device;
        Vue.set(this.filters, 'device', this.$route.params.device);
      }
      this.updateDevices();
    },
    watch: {
      currentTenant() {
        this.filters = {};
        this.updateDevices();
      },
      parameter() {
        let updated = false;
        if('connector' in this.$route.params) {
          const connector = this.$route.params.connector as any;
          if(this.filters?.connector?.uuid !== connector?.uuid) {
            this.filters = {};
            Vue.set(this.filters, 'connector', this.$route.params.connector);
            updated = true;
          }
        }
        if('property' in this.$route.params) {
          const property = this.$route.params.property as any;
          if(this.filters?.property?.uuid !== property?.uuid) {
            this.filters = {};
            Vue.set(this.filters, 'property', this.$route.params.property);
            updated = true;
          }
        }
        if('device' in this.$route.params) {
          const device = this.$route.params.device as any;
          if(this.filters?.device?.uuid !== device?.uuid) {
            this.filters = {};
            Vue.set(this.filters, 'device', this.$route.params.device);
            updated = true;
          }
        }
        if(updated)
          this.updateDevices();
      }
    },
    methods: {
      onTableReload() {
        this.updateDevices();
      },
      onDeviceFiltersApply() {
        console.log(this.filters);
        this.updateDevices();
        if(Object.keys(this.$route.query).length > 0)
          this.$router.replace({query: undefined});
      },
      onDeviceItemRestore(item: any) {
        this.updateDevices();
      },
      onDeviceItemUpdate(item: any) {
        this.devices.splice(this.devices.findIndex((prop: any) => prop.uuid === item.uuid), 1);
        this.devices.push(item);
      },
      onDeviceItemDelete(item: any) {
        this.devices.splice(this.devices.findIndex((prop: any) => prop.uuid === item.uuid), 1);
      },
      onDeviceCreation(property: any) {
        this.devices.push(property);
      },
      updateDevices() {
        this.devices = [];
        this.dataLoaded = false;

        let params = {};

        Object.assign(params, this.filters);

        DeviceService.getAllCollectionPages(this.currentTenant, params).then((devices: any[]) => {
          this.dataLoaded = true;

          this.devices = devices;
        }).catch((err: any) => {
          console.log(err, err.response);  
          this.alertType = "error";
          this.alertText = this.$t('device.loadingError') + ' ' + extractErrorMessage(err);
          this.dataError = true;     
          this.dataLoaded = true; 
        });
      }
    },
    computed: {
      currentTenant(): any {
        return this.$root.$store.state.session.selectedTenant.uuid;
      },
      canCreate(): any {
        if (this.$root.$store.state.session.permissions.resources && 'devices' in this.$root.$store.state.session.permissions.resources) {
          return this.$root.$store.state.session.permissions.resources['devices'].includes('POST');
        } else {
          return false;
        }
      },
      lastCreatedAt(): any {
        let occurrences: any = {};
        let sum = 0;

        let pastDate = new Date();
        pastDate.setDate(pastDate.getDate()-7); 

        this.devices.forEach((userObj: any) => {
          let _date = new Date(userObj.createdAt);
          if(_date >= pastDate) {
            let item = _date.getDate() + '-' + _date.getMonth();
            occurrences[item] = (occurrences[item] || 0) + 1;
            sum = sum + 1;
          }
        }, {});

        return sum;
      },
      parameter(): any {
        return this.$route.params;
      },
      query(): any {
        return this.$route.query;
      }
    }
  })
