import { render, staticRenderFns } from "./PropertiesTable.vue?vue&type=template&id=441f0f5e&scoped=true"
import script from "./PropertiesTable.vue?vue&type=script&lang=ts"
export * from "./PropertiesTable.vue?vue&type=script&lang=ts"
import style0 from "./PropertiesTable.vue?vue&type=style&index=0&id=441f0f5e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "441f0f5e",
  null
  
)

export default component.exports