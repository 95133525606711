import { render, staticRenderFns } from "./MobileAnalyticsView.vue?vue&type=template&id=3487d20d&scoped=true"
import script from "./MobileAnalyticsView.vue?vue&type=script&lang=ts"
export * from "./MobileAnalyticsView.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3487d20d",
  null
  
)

export default component.exports