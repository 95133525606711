import { render, staticRenderFns } from "./DeviceTypeEditDialog.vue?vue&type=template&id=844d5b1c&scoped=true"
import script from "./DeviceTypeEditDialog.vue?vue&type=script&lang=ts"
export * from "./DeviceTypeEditDialog.vue?vue&type=script&lang=ts"
import style0 from "./DeviceTypeEditDialog.vue?vue&type=style&index=0&id=844d5b1c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "844d5b1c",
  null
  
)

export default component.exports