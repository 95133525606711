import { render, staticRenderFns } from "./DevicesTable.vue?vue&type=template&id=605d7af7&scoped=true"
import script from "./DevicesTable.vue?vue&type=script&lang=ts"
export * from "./DevicesTable.vue?vue&type=script&lang=ts"
import style0 from "./DevicesTable.vue?vue&type=style&index=0&id=605d7af7&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "605d7af7",
  null
  
)

export default component.exports