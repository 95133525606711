
  import Vue from 'vue'

  import DeviceService from '@/services/DeviceService';
  import PropertyService from '@/services/PropertyService';
  import ConnectorService from '@/services/ConnectorService';
  import MeteringService from '@/services/MeteringService';
  import TaskEditDialog from '@/components/services/task/TaskEditDialog.vue';
  import TaskCreateDialog from '@/components/services/task/TaskCreateDialog.vue';
  import TaskDeleteDialog from '@/components/services/task/TaskDeleteDialog.vue';
  import EntityMetaDialog from '@/components/misc/EntityMetaDialog.vue';
  import QRCard from '@/components/misc/QRCard.vue';

  import { autoComplete, extractErrorMessage } from '@/utils/Util';
  import { RRuleToText } from '@/utils/Util';
import DeviceTypeService from '@/services/DeviceTypeService';
import PriceService from '@/services/PriceService';
  import { evaluate } from 'mathjs'
import DeviceComputationService from '@/services/DeviceComputationService';
import TagService from '@/services/TagService';
import TagCreateDialog from '../tag/TagCreateDialog.vue';
import DeviceAlertCreateDialog from '@/components/functions/devicealert/DeviceAlertCreateDialog.vue';
import DeviceAlertDeleteDialog from '@/components/functions/devicealert/DeviceAlertDeleteDialog.vue';
import DeviceAlertEditDialog from '@/components/functions/devicealert/DeviceAlertEditDialog.vue';
import AlertsListDialog from '../alert/AlertsListDialog.vue';

  export default Vue.extend({
    name: 'DeviceEditDialog',

    components: {
      TaskEditDialog,
      TaskCreateDialog,
      TaskDeleteDialog,
      EntityMetaDialog,
      TagCreateDialog,
      QRCard,
      DeviceAlertCreateDialog,
      DeviceAlertDeleteDialog,
      DeviceAlertEditDialog,
      AlertsListDialog
    },
    props: ['device', 'tenant'],
    data: (vm: any): any => ({
        localdialog: false,
        localDevice: {} as any,
        showAddDeviceField: false,
        valid: true,
        alertText: "",
        alertVisible: false,
        alertType: "error",
        loading: false,
        loadingProperty: false,
        loadingConnector: false,
        loadingDeviceType: false,
        loadingPrice: false,
        loadingTask: false,
        loadingDevices: false,
        loadingComputation: false,
        requiredRule: [
            (v: string) => !!v || vm.requiredError
        ],
        requiredSelect: [
            (v: any) => v !== undefined || vm.requiredError
        ],
        validFormula: [
          (v: string) => !!v || vm.requiredError,
          (v: string) => (!!v && vm.validMathFormula(v)) || vm.invalidFormulaError,
        ],
        properties: [] as any[],
        connectors: [] as any[],
        devices: [] as any[],
        sourceDevices: [] as any[],
        associatedProperty: {} as any,
        associatedConnector: {} as any,
        associatedTask: {} as any,
        associatedComputation: null,
        associatedSourceDevices: [],
        updatedAssociatedComputation: null,
        updatedAssociatedComputationFormula: {},
        updatedAssociatedSourceDevices: [],
        updatedAssociatedProperty: {} as any,
        updatedAssociatedConnector: {} as any,
        isCalculatingDevice: false,
        updatedIsCalculatingDevice: false,
        deviceTypes: [],
        devicePrices: [],
        numberOfTypeDevices: 1,
        associatedTags: [] as any[],
        updatedAssociatedTags: [] as any[],
        tags: [] as any[],
        absoluteValues: [
            { name: vm.$t('absolute'), value: false }, 
            { name: vm.$t('relative'), value: true },
        ],
        aggregationMethods: [
            {name: vm.aggregationLabel('first'), value: 'FIRST'}, 
            {name: vm.aggregationLabel('last'), value: 'LAST'},
            {name: vm.aggregationLabel('min'), value: 'MIN'}, 
            {name: vm.aggregationLabel('max'), value: 'MAX'}, 
            {name: vm.aggregationLabel('avg'), value: 'AVG'}, 
            {name: vm.aggregationLabel('med'), value: 'MEDIAN'}
        ],
        timeUnits: [
            {name: vm.$t('timeUnit.minutes'), frequency: 'MINUTELY' },
            {name: vm.$t('timeUnit.hours'), frequency: 'HOURLY'}, 
            {name: vm.$t('timeUnit.days'), frequency: 'DAILY'},
            {name: vm.$t('timeUnit.weeks'), frequency: 'WEEKLY'},
            {name: vm.$t('timeUnit.months'), frequency: 'MONTHLY'},
            {name: vm.$t('timeUnit.years'), frequency: 'YEARLY'}
        ],
        deviceUnits: [
            "m", "km", "cm", "mm", "µm", "nm", "m²", "km²", "cm²", "mm²", "ha", "m³", "cm³", "mm³", "L", "mL", "m³/h", "L/h", "mL/h", "kg", "g", "mg", "µg", "t", "s", "ms", "µs", "ns", "min", "h", "d", "wk", "yr", "J", "kJ", "GJ", "cal", "kcal", "Wh", "kWh", "MWh", "eV", "W", "kW", "MW", "GW", "°C", "°F", "K", "Pa", "kPa", "bar", "atm", "Torr", "psi", "m/s", "km/h", "kn", "Hz", "kHz", "MHz", "GHz", "V", "kV", "VAr", "kVAr", "A", "mA", "Ω", "C", "B", "KB", "MB", "GB", "TB", "PB", "pulse", "ping", "binary", "%", "ppm", "lm", "lx", "dB", "N.N."
        ],
        readingValidationRules: [
            {name: 'Greater than previous', value: 'greater_previous' },
            {name: 'Greater than zero', value: 'greater_zero' },
            {name: 'Greater than or equal to previous', value: 'greater_equal_previous' },
            {name: 'Greater than or equal to zero', value: 'greater_equal_zero' },
            {name: 'Outlier Detection', value: 'outlier_detection' },
        ],
        readingValidationActions: [
            {name: 'Quarantine', value: 'quarantine' },
            {name: 'Notify', value: 'notify' },
            {name: 'Discard', value: 'discard' },
            {name: 'Do nothing', value: 'nothing' },
        ],
        associatedAlerts: [] as any[],
    }),
    async created() {
        this.localDevice = Object.assign({}, this.device);
    },
    methods: {
      aggregationLabel(label: string) {
        return this.$t('aggregation.'+label);
      },
      onTaskChange(task: any) {
        this.associatedTask = task;
      },
      onTaskDelete(task: any) {
        this.associatedTask = {};
      },
      onCreateTask(task: any) {
          this.associatedTask = task;
      },
      openAssociatedReadings() {
        this.$router.push({ name: 'Readings', params: { device: this.device } });
      },
      openAssociatedSetpoints() {
        this.$router.push({ name: 'Setpoints', params: { device: this.device } });
      },
      onRemoveTagClick(tag: any) {
        this.updatedAssociatedTags.splice(this.updatedAssociatedTags.findIndex((t: any) => t.uuid === tag.uuid), 1);
      },
      onDeviceTypeChange() {
        this.getNumberOfTypeDevices();
      },
      close() {
        this.localdialog = false;
      },
      clear() {
        this.properties = [];
        this.associatedAlerts = [];
        this.connectors = [];
        this.associatedTags = [];
        this.tags = [];
        this.updatedAssociatedTags = [];
        this.associatedProperty = {};
        this.associatedConnector = {};
        this.updatedAssociatedProperty = {};
        this.updatedAssociatedConnector = {};
        this.numberOfReadings = 0;
        this.numberOfSetPoints = 0;
        this.alertVisible = false;
        this.isCalculatingDevice = false;
        this.loading = false;
        this.loadingDeviceType = false;
        this.loadingComputation = false;
        this.deviceTypes = [];
        this.devicePrices = [];
        this.loadingPrice = false;
        this.numberOfTypeDevices = 1;
        this.associatedComputation = null;
        this.associatedSourceDevices = [];
        this.updatedAssociatedComputation = null;
        this.updatedAssociatedSourceDevices = [];
        this.updatedAssociatedComputationFormula = {};
      },
      dismiss() {
        this.clear();
        this.close();
      },
      validate() {
        if(this.editForm.validate()) {
          this.save();
        }
      },
      async save() {
        this.alertVisible = false;
        this.loading = true;

        let aksId = "";
        let localAksId = "";
        let description = "";
        let unit = "";
        let associatedPropertyUUID = "";
        let associatedConnectorUUID = "";
        let aggregation = "";
        let type = "";
        let frequency = "";
        let interval = -1;
        let isRelative = false;
        let alerting = true;

        if(this.localDevice.aksId !== this.device.aksId) {
            aksId = this.localDevice.aksId;
        }
        if(this.localDevice.localAksId !== this.device.localAksId) {
            localAksId = this.localDevice.localAksId;
        }
        if(this.localDevice.description !== this.device.description) {
            description = this.localDevice.description;
        }
        if(this.localDevice.unit !== this.device.unit) {
            unit = this.localDevice.unit;
        }
        if(this.localDevice.aggregation !== this.device.aggregation) {
            aggregation = this.localDevice.aggregation;
        }
        if(this.localDevice.type !== this.device.type) {
            type = this.localDevice.type;
        }
        if(this.localDevice.frequency !== this.device.frequency) {
            frequency = this.localDevice.frequency;
        }
        if(this.localDevice.interval !== this.device.interval) {
            interval = parseInt(this.localDevice.interval);
        }
        if(this.localDevice.isRelative !== this.device.isRelative) {
            isRelative = this.localDevice.isRelative;
        }
        if(this.localDevice.alerting !== this.device.alerting) {
            alerting = this.localDevice.alerting;
        }
        if(Object.keys(this.updatedAssociatedProperty).length > 0 && this.updatedAssociatedProperty !== this.associatedProperty) {
            associatedPropertyUUID = this.updatedAssociatedProperty.uuid;
        }        
        if(Object.keys(this.updatedAssociatedConnector).length > 0 && this.updatedAssociatedConnector !== this.associatedConnector) {
            associatedConnectorUUID = this.updatedAssociatedConnector.uuid;
        }
        
        let addedTags = this.updatedAssociatedTags.filter((value: any) => this.associatedTags.findIndex((tag: any) => tag.uuid === value.uuid) < 0); // new tags
        let removedTags = this.associatedTags.filter((value: any) => this.updatedAssociatedTags.findIndex((tag: any) => tag.uuid === value.uuid) < 0);  // removed tags

        let device: any;
        try {

            if(addedTags.length > 0)
                await DeviceService.addTags(this.currentTenant, this.device.uuid, addedTags);
            if(removedTags.length > 0)
                await DeviceService.removeTags(this.currentTenant, this.device.uuid, removedTags);

          device = await DeviceService.updateDevice(this.currentTenant, 
            this.device.uuid, aksId, localAksId, description, unit, type, frequency, interval, aggregation, 
            associatedPropertyUUID, associatedConnectorUUID, alerting, isRelative);

          this.localDevice = device;

          this.alertType = "success";
          this.alertText = this.$t('device.successUpdate');
          this.alertVisible = true;
        } catch(err: any) {
          console.log(err, err.response);  
          this.alertType = "error";
          this.alertText = this.$t('device.errorUpdate') + ' ' + extractErrorMessage(err);
          this.alertVisible = true;
          this.loading = false;
        }

        if(this.isCalculatingDevice && !this.updatedIsCalculatingDevice) {
            // remove computation
            try {
                await DeviceComputationService.deleteDeviceComputation(this.currentTenant, this.associatedComputation.uuid);
            } catch(err: any) {
                console.log(err, err.response);  
                this.alertType = "error";
                this.alertText = this.$t('deviceComputation.errorDelete') + ' ' + extractErrorMessage(err);
                this.alertVisible = true;
                this.loading = false;
            }
        }
        
        // create computation for new device
        if(this.updatedIsCalculatingDevice) {
        
            let formula = this.updatedAssociatedComputationFormula.formula;
            let scope = this.updatedAssociatedSourceDevices.reduce((o: any, dev: any) => ({ ...o, [dev.variable]: dev.uuid }), {});

            if(formula.includes('self') && this.updatedAssociatedSourceDevices.findIndex((value: any) => value.uuid === device.uuid) < 0) {
                scope['self'] = device.uuid;
            } else if(!formula.includes('self') && this.updatedAssociatedSourceDevices.findIndex((value: any) => value.uuid === device.uuid) >= 0) {
                delete scope['self'];
            }

            try {
                if(!this.isCalculatingDevice && this.updatedIsCalculatingDevice) {
                    await DeviceComputationService.createDeviceComputation(this.currentTenant, device.uuid, formula, scope);
                } else if(this.isCalculatingDevice && this.updatedIsCalculatingDevice) {
                    await DeviceComputationService.updateDeviceComputation(this.currentTenant, this.updatedAssociatedComputation.uuid, device.uuid, formula, scope);
                }
                this.alertType = "success";
                this.alertText = this.$t('deviceComputation.successUpdate');
                this.alertVisible = true;

                this.$emit("update", device);
                setTimeout(() => { 
                    this.dismiss(); 
                }, 1500);
            } catch(err: any) {
                console.log(err, err.response);  
                this.alertType = "error";
                this.alertText = this.$t('deviceComputation.errorUpdate') + ' ' + extractErrorMessage(err);
                this.alertVisible = true;
                this.loading = false;
            }
        } else {
            this.$emit("update", device);
            setTimeout(() => { 
                this.dismiss(); 
            }, 1500);
        }
      },
      getDeviceTypes() {
        this.loadingDeviceType = true;
        this.deviceTypes = [];

        let params = {} as any;

        params.tenantId = this.currentTenant;

        DeviceTypeService.getAllCollectionPages(params).then((devicetypes: any[]) => {
            this.loadingDeviceType = false;

            this.deviceTypes = devicetypes;
        }).catch((err: any) => {
            console.log(err, err.response);  
            this.alertType = "error";
            this.alertText = this.$t('deviceType.loadingError') + ' ' + extractErrorMessage(err);
            this.alertVisible = true;     
            this.loadingDeviceType = false; 
        });
      },
      getDevices() {
        this.loadingDevices = true;

        DeviceService.getAllCollectionPages(this.currentTenant).then((devices: any[]) => {
          this.loadingDevices = false;
          // TODO the process of assigning variable names to devices need to be overthought
          // when new devices are added, or ones deleted in the backend, variable names STILL need to be correct

          this.devices = devices.map((value: any, index: number) => ({ variable: 'd'+index, ...value }));
        }).catch((err: any) => {
          console.log(err, err.response);  
          this.alertType = "error";
          this.alertText = this.$t('device.loadingError') + ' ' + extractErrorMessage(err);
          this.alertVisible = true;    
          this.loadingDevices = false;  
        });
      },
      getAssociatedProperty() {
        this.loadingProperty = true;

        DeviceService.getAssociatedProperty(this.currentTenant, this.device.uuid).then((property: any) => {
            this.loadingProperty = false;

            this.associatedProperty = property;
            this.updatedAssociatedProperty = this.associatedProperty;
        }).catch((err) => {
            console.log(err);
            console.log(err.response);
            this.alertType = "error";
            this.alertText = this.$t('device.errorAssociatedProperty') + ' ' + extractErrorMessage(err);
            this.alertVisible = true; 
            this.loadingProperty = false;
        });
      },
      getAssociatedTask() {
        this.loadingTask = true;
        
        MeteringService.getDeviceTask(this.currentTenant, this.device.uuid).then((task) => {
            this.loadingTask = false;

            this.associatedTask = task
            }).catch((err) => {
                if(err.response && err.response.status !== 404) {
                    console.log(err);
                    console.log(err.response);
                }
                this.loadingTask = false;
            });
      },
      getAssociatedConnector() {
        this.loadingConnector = true;

        DeviceService.getAssociatedConnector(this.currentTenant, this.device.uuid).then((connector: any) => {
            this.loadingConnector = false;

            this.associatedConnector = connector;
            this.updatedAssociatedConnector = this.associatedConnector;
        }).catch((err) => {
            console.log(err);
            console.log(err.response);
            this.alertType = "error";
            this.alertText = this.$t('device.errorAssociatedConnector') + ' ' + extractErrorMessage(err);
            this.alertVisible = true; 
            this.loadingConnector = false;
        });
      },
      getAssociatedReadings() {
        this.loading = true;

        DeviceService.getAssociatedReadingsCount(this.currentTenant, this.device.uuid).then((count: number) => {
            this.loading = false;

            this.numberOfReadings = count;
        }).catch((err) => {
            console.log(err);
            console.log(err.response);
            this.alertType = "error";
            this.alertText = this.$t('device.errorAssociatedReadings') + ' ' + extractErrorMessage(err);
            this.alertVisible = true; 
            this.loading = false;
        });
      },
      getAssociatedSetPoints() {
        this.loading = true;

        DeviceService.getAssociatedSetPointsCount(this.currentTenant, this.device.uuid).then((count: number) => {
            this.loading = false;

            this.numberOfSetPoints = count;
        }).catch((err) => {
            console.log(err);
            console.log(err.response);
            this.alertType = "error";
            this.alertText = this.$t('device.errorAssociatedSetpoints') + ' ' + extractErrorMessage(err);
            this.alertVisible = true; 
            this.loading = false;
        });
      },
      getProperties() {
        this.properties = [];
        this.loading = true;
        
        PropertyService.getAllCollectionPages(this.currentTenant).then((properties: any[]) => {
            this.loading = false;

            this.properties = properties;
        }).catch((err: any) => {
            console.log(err, err.response);  
            this.alertType = "error";
            this.alertText = this.$t('property.loadingError') + ' ' + extractErrorMessage(err);
            this.alertVisible = true; 
            this.loading = false;     
        });
      },
      getConnectors() {
        this.connectors = [];
        this.loading = true;

        ConnectorService.getAllCollectionPages(this.currentTenant).then((connectors: any[]) => {
          this.loadingConnectors = false;

          this.connectors = connectors;
        }).catch((err: any) => {
          console.log(err, err.response);  
          this.alertType = "error";
          this.alertText = this.$t('connector.loadingError') + ' ' + extractErrorMessage(err);
          this.alertVisible = true;  
          this.loadingConnectors = false;    
        });
      },
      getDevicePrices() {
        this.loadingPrice = true;

        let params = {} as any;

        params.type = 'devices';
        params.tenantId = this.currentTenant;

        PriceService.getAllCollectionPages(params).then((prices: any[]) => {
            this.loadingPrice = false;

            this.devicePrices = prices;
        }).catch((err: any) => {
            console.log(err, err.response);  
            this.alertType = "error";
            this.alertText = this.$t('price.loadingError') + ' ' + extractErrorMessage(err);
            this.alertVisible = true;    
            this.loadingPrice = false;  
        });
      },
      async getAssociatedComputation() {
        this.loadingComputation = true;

        try {
            const computations = await DeviceComputationService.getAllCollectionPages(this.currentTenant, { targetDeviceId: this.device.uuid});
            this.loadingComputation = false;
            if(computations.length > 0) {
                this.isCalculatingDevice = true;
                this.updatedIsCalculatingDevice = true;
                this.associatedComputation = computations[0];
                this.updatedAssociatedComputation = computations[0];
                this.updatedAssociatedComputationFormula = { name: this.$t("computationHeaders.custom"), formula: computations[0].formula, multiple: true, single: true, any: true },
                this.associatedSourceDevices = await DeviceComputationService.getSourceDevices(this.currentTenant, this.associatedComputation.uuid);
                this.updatedAssociatedSourceDevices = this.associatedSourceDevices;
            } else {
                this.device.variable = 'self';
                this.updatedAssociatedSourceDevices = [this.device];
            }
        } catch(err: any) {
            console.log(err);
            console.log(err.response);
            this.loadingComputation = false;
        }
      },
      getNumberOfTypeDevices() {
          this.numberOfTypeDevices = 1;
          this.loadingPrice = true;

          let params = { type: this.localDevice.type, itemsPerPage: 1 };

          DeviceService.getCount(this.currentTenant, params).then((count: number) => {
            this.loadingPrice = false;

            if(count) {
                this.numberOfTypeDevices = count+1; // ML: plus one because this device is not included
            }
          }).catch((err: any) => {
            console.log(err, err.response);  
            this.alertType = "error";
            this.alertText = this.$t('device.loadingError') + ' ' + extractErrorMessage(err);
            this.alertVisible = true;     
            this.loadingPrice = false; 
          });
      },
      getAssociatedTags() {
        this.loading = true;

        DeviceService.getAssociatedTags(this.currentTenant, this.device.uuid).then((tags: any[]) => {
            this.associatedTags = tags;
            this.updatedAssociatedTags = [...tags];
            this.loading = false;
        }).catch((err) => {
            console.log(err);
            console.log(err.response);
            this.alertType = "error";
            this.alertText = this.$t('property.errorAssociatedDevice') + ' ' + extractErrorMessage(err);
            this.alertVisible = true; 
            this.loading = false;
        });
      },
      getAssociatedAlerts() {
        this.loading = true;

        DeviceService.getAssociatedAlerts(this.$root.$store.state.session.selectedTenant.uuid, this.device.uuid).then((alerts: any[]) => {
            this.associatedAlerts = alerts;
            this.loading = false;
        }).catch((err) => {
            console.log(err);
            console.log(err.response);
            this.alertType = "error";
            this.alertText = this.$t('alert.loadingError') + ' ' + extractErrorMessage(err);
            this.alertVisible = true; 
            this.loading = false;
        });
      },
      getTags() {
        this.loading = true;

        TagService.getAllCollectionPages(this.$root.$store.state.session.selectedTenant.uuid).then((tags: any[]) => {
            this.tags = tags;
            this.loading = false;
        }).catch((err) => {
            console.log(err);
            console.log(err.response);
            this.alertType = "error";
            this.alertText = this.$t('property.errorAssociatedDevice') + ' ' + extractErrorMessage(err);
            this.alertVisible = true; 
            this.loading = false;
        });
      },
      findDiscount(price: any, index: number) {
        let discountRange;
        if(price && price.discountRange) {
            for(let j=0; j<price.discountRange.length; j++) {
                const dis = price.discountRange[j];
                const lastDis = j>0 ? price.discountRange[j-1] : null;

                if(dis.start!==null && dis.start <= index && ( (dis.end!==null && dis.end >= index) || (dis.end==null && lastDis && lastDis.end!==null && lastDis.end < index) ) ) {
                discountRange = price.discountRange[j];
                }
            }
        }
        return discountRange;
      },
      validMathFormula(formula: string) {
        try {
          evaluate(formula, this.selectedFormulaScope);
          return true;
        } catch(e: any) {
          console.log(e)
          return false;
        }
      },
      toCurrencyString(price: number) {
          return price ? price.toLocaleString(undefined, { style: 'currency', currency: 'EUR' }) : null;
      },
      async onAlertDelete(item: any) {
        this.associatedAlerts.splice(this.associatedAlerts.findIndex((value: any) => value.uuid === item.uuid), 1);
      },
      async onAlertCreate(item: any) {
        this.associatedAlerts.push(item);
      },
      async onAlertUpdate(item: any) {
        this.associatedAlerts.splice(this.associatedAlerts.findIndex((value: any) => value.uuid === item.uuid), 1);
        this.associatedAlerts.push(item);
      },
      autoComplete,
    },
    watch: {
        device() {
            this.localDevice = Object.assign({}, this.device);
        },
        async localdialog(value: boolean) {
            if(value) {
                this.getNumberOfTypeDevices();
                this.getDevicePrices();
                this.getDeviceTypes();
                this.getAssociatedProperty();
                this.getAssociatedConnector();
                this.getAssociatedTask();
                this.getProperties();
                this.getConnectors();
                this.getDevices();
                this.getAssociatedComputation();
                this.getAssociatedTags();
                this.getTags();
                this.getAssociatedAlerts();
            } else {
                this.clear();
            }
        }
    },
    computed: {
        activeAlert() {
            return this.associatedAlerts.some((a: any) => a.active);
        },
        calculationFormulas() {
          return [
            { header: this.$t("computationHeaders.aggregation") },
            { name: this.$t("computations.sum"), formula: "sum("+ this.selectedFormulaVariables.join() + ")", multiple: true, single: false },
            { name: this.$t("computations.average"), formula: "mean("+ this.selectedFormulaVariables.join() + ")", multiple: true, single: false },
            { name: this.$t("computations.diffToAbsolute"), formula: " self + " + this.selectedFormulaVariables[0], multiple: false, single: true },
            { name: this.$t("computations.diffToAbsoluteFactor"), formula: " self + (" + this.selectedFormulaVariables[0] + " * FACTOR)", multiple: false, single: true },
            { header: this.$t("computationHeaders.unitconversion") },
            { name: "W -> kW", formula: this.selectedFormulaVariables[0] +" / 1000.0", multiple: false, single: true },
            { name: "kW -> W", formula: this.selectedFormulaVariables[0] +" * 1000.0", multiple: false, single: true },
            { name: "cm³ -> m³", formula: this.selectedFormulaVariables[0] +" / 1000000.0", multiple: false, single: true },
            { name: "m³ -> cm", formula: this.selectedFormulaVariables[0] +" * 1000000.0", multiple: false, single: true },
            { name: "inch³ -> cm³", formula: this.selectedFormulaVariables[0] +" * 16.387064", multiple: false, single: true },
            { name: "cm³ -> inch³", formula: this.selectedFormulaVariables[0] +" / 16.387064", multiple: false, single: true },
            { name: "feet³ -> m³", formula: this.selectedFormulaVariables[0] +" * 0.02832", multiple: false, single: true },
            { name: "m³ -> feet³", formula: this.selectedFormulaVariables[0] +" / 0.02832", multiple: false, single: true },
            { name: "yard³ -> m³", formula: this.selectedFormulaVariables[0] +" * 0.76456", multiple: false, single: true },
            { name: "m³ -> yard³", formula: this.selectedFormulaVariables[0] +" / 0.76456", multiple: false, single: true },
            { name: "C° -> °F", formula: "(" + this.selectedFormulaVariables[0] +" * 9/5) + 32", multiple: false, single: true },
            { name: "°F -> C°", formula: "(" + this.selectedFormulaVariables[0] +" - 32) * 9/5", multiple: false, single: true },
            { header: this.$t("computationHeaders.custom") },
            { name: this.$t("computationHeaders.custom"), formula: "", multiple: true, single: true, any: true },
          ]; 
        },
        selectedFormulaVariables() {
          return this.updatedAssociatedSourceDevices.map((value: any) => value.variable);
        },
        selectedFormulaScope() {
          return this.updatedAssociatedSourceDevices.reduce((o: any, dev: any) => ({ ...o, [dev.variable]: 1, [dev.variable + "_prev"]: 1  }), {});
        },
        filteredCalculationFormulas() {
        //   console.log(this.updatedAssociatedSourceDevices)
          return this.calculationFormulas.filter((value: any) => this.updatedAssociatedSourceDevices.length > 1 ? value.multiple || value.header : this.updatedAssociatedSourceDevices.length > 0);
        },  
        calculatedDevicePriceRange(): any {
            const ret = this.devicePrices.find((element: any) => element.subtype === this.localDevice.type);

            if(this.devicePrices.length > 0 && ret) {
                // construct range from 0 discount to possible max. discount 
                // (based on number of devices from this type)
                let discountedPrice = ret.basePrice;
                const discount = this.findDiscount(ret, this.numberOfTypeDevices);
                if(discount) {
                    discountedPrice = discountedPrice * (1-discount.discount);
                }

                if(ret.basePrice === discountedPrice)
                    return this.toCurrencyString(ret.basePrice);

                return ret.basePrice > discountedPrice ? this.toCurrencyString(discountedPrice) + ' - ' + this.toCurrencyString(ret.basePrice) :
                    this.toCurrencyString(ret.basePrice) + ' - ' + this.toCurrencyString(discountedPrice);
            }
            return null;
        },
        retentionTime(): any {
          const ret = this.deviceTypes.find((element: any) => element.type === this.localDevice.type);

          return ret ? ret.retention / 86400000 : 0;
        },
        emptyTask(): any {
            return Object.keys(this.associatedTask).length === 0;
        },
        rruleText(): any {
            if(Object.keys(this.associatedTask).length > 0) {
                const text = RRuleToText(this.associatedTask.rrule, this.$root.$store.state.config.language);
                return text.charAt(0).toUpperCase()+text.slice(1);
            } else {
                return "";
            }
        },
        isAdmin(): any {
            return this.$root.$store.state.session.permissions && 'role' in this.$root.$store.state.session.permissions
                && (this.$root.$store.state.session.permissions.role === 'superadmin' || 
                this.$root.$store.state.session.permissions.role === 'admin');
        },
        editForm(): any {
            return this.$refs.editForm;
        },
        requiredError(): any {
            return this.$t('required');
        },
        currentTenant(): any {
          return this.tenant ? this.tenant.uuid : this.$root.$store.state.session.selectedTenant.uuid;
        },
        invalidFormulaError(): any {
          return 'Invalid Formula';
        },
    }
  })
